// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chapters-00-home-js": () => import("/opt/build/repo/src/pages/chapters/00-home.js" /* webpackChunkName: "component---src-pages-chapters-00-home-js" */),
  "component---src-pages-chapters-01-origins-js": () => import("/opt/build/repo/src/pages/chapters/01-origins.js" /* webpackChunkName: "component---src-pages-chapters-01-origins-js" */),
  "component---src-pages-chapters-02-technology-js": () => import("/opt/build/repo/src/pages/chapters/02-technology.js" /* webpackChunkName: "component---src-pages-chapters-02-technology-js" */),
  "component---src-pages-chapters-03-dovetail-js": () => import("/opt/build/repo/src/pages/chapters/03-dovetail.js" /* webpackChunkName: "component---src-pages-chapters-03-dovetail-js" */),
  "component---src-pages-chapters-04-scaling-js": () => import("/opt/build/repo/src/pages/chapters/04-scaling.js" /* webpackChunkName: "component---src-pages-chapters-04-scaling-js" */),
  "component---src-pages-chapters-05-growth-js": () => import("/opt/build/repo/src/pages/chapters/05-growth.js" /* webpackChunkName: "component---src-pages-chapters-05-growth-js" */),
  "component---src-pages-chapters-06-expansion-js": () => import("/opt/build/repo/src/pages/chapters/06-expansion.js" /* webpackChunkName: "component---src-pages-chapters-06-expansion-js" */),
  "component---src-pages-chapters-07-partnership-js": () => import("/opt/build/repo/src/pages/chapters/07-partnership.js" /* webpackChunkName: "component---src-pages-chapters-07-partnership-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

